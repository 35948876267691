import React from 'react'
//optimizer
export default ({color,height,padding}) => {
    color = color || "darkblue";
    padding = padding || 10;
    height = height || 100;

    var style = {
        height: height - padding
    }
    var divStyle = {
        padding: padding
    }

    return (<div style={divStyle}>

<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 128.479 28.348" {...style}>
  <g>
    <path 
        d="M9.853 23.624H5.299v-18.9h4.554zm7.675 0l-6.527-9.547 7.146-9.352h4.309l-7.082 8.346 7.675 10.553z" 
        fill={color}
        />
    <path 
        fill="none" 
        stroke={color}
        strokeDashoffset=".6" 
        strokeWidth="1.89" 
        d="M.945.945h26.458v26.458H.945z" 
        />
    <path 
        d="M39.665 19.312h-6.479l-1.236 4.41h-3.838l6.063-19.06h5.542l6.05 19.06h-4.853zm-.716-2.64L37.193 10.4l-.65-2.862h-.26l-.638 2.862-1.756 6.271zM51.173 23.723V4.663h4.592v16.223h7.143l-.508 2.837zM68.802 23.723V4.663h12.437l-.507 2.836h-7.338v5.061h6.401l-.468 2.641h-5.933v5.685h7.975l-.507 2.837zM87.693 23.723V4.663h6.244l6.336 10.408 1.418 2.836h.26l-.143-3.187V4.663h3.656v19.06h-4.774l-7.897-12.88-1.327-2.706h-.26l.142 2.992v12.594zM122.378 19.312h-6.48l-1.235 4.41h-3.838l6.062-19.06h5.543l6.05 19.06h-4.853zm-.716-2.64l-1.756-6.272-.65-2.862h-.26l-.638 2.862-1.757 6.271z" 
        fill={color}
        />
  </g>
</svg>
    </div>
    )

}
