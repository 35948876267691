import React, { Component, lazy, Suspense } from 'react';

import './index.css';
import { BrowserRouter as Router, Route } from "react-router-dom"

import Loader from './Loader'

const Privacy = lazy(() => import('../components/scene/Privacy'))
const Home = lazy(() => import('../components/scene/Home'))
const Life = lazy(() => import('../components/scene/Life'))


class App extends Component {
  render() {
    return (
      <Router>
        <Suspense fallback={<Loader />}>
          <Route exact path="/" component={Home} />
          <Route path="/life" component={Life} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/loader" component={Loader} />
        </Suspense>
      </Router>
    );
  }
}

export default App;
